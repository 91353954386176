import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/App.css";
import { Result, Button} from "antd";



class Success extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      value: 1,
    };
  }
  
handleback = (e) => {
    this.props.history.push("/dashboard");
} 

  render() {
   
   
    return (
      <div className="dashboard-wrapper">
        
        <Result
    status="success"
    title="Data Successfully Submitted"
    extra={[
      <Button onClick={this.handleback} type="primary" key="console">
        Go Dashboard
      </Button>,
    ]}
  />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, {})(Success);
