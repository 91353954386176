import { GET_USER, USER_STATUS } from "../actionTypes";
import request from "superagent";
import { APIURL} from "../actionTypes";

export function getUser() {
  return (dispatch) => {
    // return new Promise((resolve, reject) => {
    // show loading status before getting user to true
    dispatch({
      type: USER_STATUS,
      payload: true,
    });
    if (localStorage.getItem('user')) 
    {
      var user = localStorage.getItem('user');
      console.log(user)
     dispatch({type: GET_USER,payload: JSON.parse(user)});
    }
    else
    {
      user = null;
      dispatch({type: GET_USER,payload: user});
      console.log(user)
    } 
  
   dispatch({
    type: USER_STATUS,
    payload: false,
  });
  };
}

export function emailLogin(values) {
  return dispatch => {
    return new Promise((resolve, reject) => {

      console.log(values)
    var obj_arr = {
    storeid:values.stroename.toString(),
    password:values.password,
    employeeid:values.empname.toString()
  }
    request.post(APIURL+'/login')
    //.set('Content-Type', 'application/json')
    .send(obj_arr)
    .then(res => {
       // res.body, res.headers, res.status
       console.log(res.body, res.headers, res.status)
       if(res.body.result_code === 200)
       {
        localStorage.setItem("user", JSON.stringify(res.body));
        resolve(res.body)
        dispatch({type: GET_USER,payload: res.body});
       }
       
       reject(res.body)
    })
    .catch(err => {
       // err.message, err.response
 reject(err.message)
    });
  })
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: USER_STATUS,
      payload: true,
    });
    var userid = localStorage.getItem('user');
      var AuthToken = JSON.parse(userid)
      var obj_arr = {
        AuthToken:AuthToken.AuthToken,
      }
    request.post(APIURL+'/logout')
    //.set('Content-Type', 'application/json')
    .send(obj_arr)
    .then(res => {
       // res.body, res.headers, res.status
       console.log(res.body, res.headers, res.status)
       dispatch({type: GET_USER,payload: null});
       localStorage.removeItem('user');
       sessionStorage.removeItem('softvalue');
       sessionStorage.removeItem('state');
       sessionStorage.removeItem('vendordevice');
       dispatch({
        type: USER_STATUS,
        payload: false,
      });
    })
    .catch(err => {
       // err.message, err.response
    });
  }
 
}

