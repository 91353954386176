import React, { Component } from "react";
import logo from "../assets/img/wds-logo.svg";
import { connect } from "react-redux";
import "../assets/css/App.css";
import { Form, Input, Button, Checkbox, Card, Select,message } from "antd";
import {emailLogin} from "../actions/userAction";
import {getDistrictandStoreinfo} from "../actions/wdsAction"
import _ from 'lodash';

const { Option } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
class LogIn extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      getdistrict:[],
      getemployeeinfo:[],
      getstoreinfo:[],
      DistrictId:0,
      StoreInformationId:0,
      storename: '',
      employee:'',
      password: '',
      isChecked: true,
    };
  }
  
  
  componentDidMount()
  {
    if (this.props.user !== null) {
      this.props.history.push('/dashboard');
    }
    else
    {
      
        this.setState({
          storename: localStorage.getItem("storename"),
          employee : localStorage.getItem("employee"),
          password : localStorage.getItem("password"),
          StoreInformationId:localStorage.getItem("storename")
        })
      
   this.props.getDistrictandStoreinfo()
    .then(res => {
       // res.body, res.headers, res.status
      // console.log(res.body, res.headers, res.status)
       this.setState({ getdistrict: res.getdistrict,getemployeeinfo:res.getemployeeinfo,getstoreinfo:res.getstoreinfo})
       this.formRef.current.setFieldsValue({
        password: this.state.password,
        empname: this.state.employee,
        stroename : this.state.storename
      });
    })
    .catch(err => {
       // err.message, err.response
    });
  }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user !== null) {
      nextProps.history.push('/dashboard');
    }
  }

  getdistrict()
  {
       return _.map(this.state.getdistrict,(getdistrict,key) => {
              return (
                <Option value={getdistrict.DistrictId} key={key}> 
                {getdistrict.DistrictName}
                </Option>
              );
            })
  }
  getemployeeinfo()
  {
       return _.map(this.state.getemployeeinfo,(getdistrict,key) => {
        if(getdistrict.StoreInformationId === this.state.StoreInformationId)
        {
              return (
                <Option value={getdistrict.EmployeeId} key={key}> 
                {getdistrict.Name}
                </Option>
              );
        }
            })
  }
  getstoreinfo()
  {
       return _.map(this.state.getstoreinfo,(getdistrict,key) => {
          return (
            <Option value={getdistrict.StoreInformationId.toString()} key={key}> 
            {getdistrict.Storename}
            </Option>
          );
         
        })
  }
  onChangeDistrict = (value) => {
    console.log(`selected ${value}`);
    this.setState({DistrictId:value.toString()})
  }
  onChangeStore = (value) => {
    console.log(`selected ${value}`);
    this.setState({StoreInformationId:value.toString(),storename:value});
    this.formRef.current.setFieldsValue({
      empname: undefined,
      password: undefined
  })    
  }
  onChangeEmp = (value) => {
    console.log(`selected ${value}`);
    this.setState({employee:value})
  }

  
  onFinish = (values) => {
    const { storename,employee,isChecked } = this.state
        if ((isChecked === true) && (storename !== "")&& (employee !== "")) 
        {
            localStorage.setItem ("storename",storename.toString())
            localStorage.setItem ("employee",employee.toString())
            localStorage.setItem ("password",values.password.toString())
          
        }
        else
        {
          localStorage.removeItem ("storename")
          localStorage.removeItem ("employee")
          localStorage.removeItem ("password")
        }
    this.setState({
      loading : true
    })
    this.props.emailLogin(values)
    .then((succ) => {
      message.success("Logged in!");
      this.props.history.push('/dashboard');
    })
    .catch((err) => 
      {
        message.error("Your Username or Password is incorrect! Try again.");
        this.setState({
          loading : false
        })
      });
    
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  onChangeCheckbox = event => {
    this.setState({
        isChecked: event.target.checked
    })
}

  render() {
    
    return (
      <div className="login-wrapper">
        <Card className="loginform">
          <div className="text-center login-title">
            <img src={logo} alt="logo" />
            <h4><b>Soft Count WebApp</b></h4>
          </div>

          <Form
            {...layout}
            name="basic"
            ref={this.formRef}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            {/* <Form.Item
              label="District"
              name="District"
              rules={[
                { required: true, message: "Please select your district!" },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a district"
                optionFilterProp="children"
                onChange={this.onChangeDistrict}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
               {this.getdistrict()}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Store name"
              name="stroename"
              rules={[
                { required: true, message: "Please select your store!" },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a store"
                optionFilterProp="children"
                onChange={this.onChangeStore}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.getstoreinfo()}
              </Select>
            </Form.Item>
            <Form.Item
              label="Employee name"
              name="empname"
              rules={[
                { required: true, message: "Please select employee name!" },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select employee name"
                optionFilterProp="children"
                onChange={this.onChangeEmp}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.getemployeeinfo()}
              </Select>
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password  style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item {...tailLayout} name="remember">
              <Checkbox checked={this.state.isChecked} onChange={this.onChangeCheckbox}>Remember me</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button loading={this.state.loading} type="primary" block htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, info:state.info };
}

export default connect(mapStateToProps, {emailLogin,getDistrictandStoreinfo})(LogIn);
