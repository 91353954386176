import React, { Component } from "react";
import { connect } from "react-redux";
import {softcountaddconfirm,softcountaddreview} from "../actions/wdsAction";
import "../assets/css/App.css";
import { Button,Typography,Table,Input ,Form,Spin,message } from "antd";
import _ from 'lodash';
import {
LoadingOutlined
} from "@ant-design/icons";
const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

class Confirmation extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      value: 1,
      vendordevice:[],
      loading: true
    };
  }

componentDidMount(){
  if(this.props.location.data === undefined)
  {
    const serializedState = sessionStorage.getItem('vendordevice')
    var devicedata = JSON.parse(serializedState)
    var softvalue = sessionStorage.getItem('softvalue')
    this.setState({softvalue:sessionStorage.getItem('softvalue')})
    if(softvalue === "1")
    {
      this.props.softcountaddreview(softvalue, devicedata)
      .then(res => {
        console.log(res)
        const results= res.vendordevice.map(row => ({
          key: row.VendorDeviceId, // I added this line
          vendordeviceid:row.VendorDeviceId,
          ProductSKU: row.ProductSKU,
          isMorningDeficit: row.isMorningDeficit,
          PortalProductName: row.PortalProductName,
          stock: (parseInt(res.morningsoftcountstatus) === 0 ? row.MorningStock : row.NightStock),
        }))
        const vendordevice = _.orderBy(results, ['stock'],['desc']);
        this.setState({loading: false, vendordevice: vendordevice,softvalue:sessionStorage.getItem('softvalue') })
      })
      .catch(err =>
      {
        console.log(err)
        message.error(err.message);
        this.props.history.push("/dashboard");
      })
    }
    else
    {
   const vendordevice = _.orderBy(devicedata, ['stock'],['desc']);
   this.setState({loading: false, vendordevice: vendordevice,softvalue:sessionStorage.getItem('softvalue') });
    }
  }
  else
  {
    devicedata = this.props.location.data
    console.log(devicedata);
    softvalue = sessionStorage.getItem('softvalue')
    this.setState({softvalue:sessionStorage.getItem('softvalue')})
    if(softvalue === "1")
    {
      this.props.softcountaddreview(softvalue, devicedata)
      .then(res => {
        console.log(res)
        const results= res.vendordevice.map(row => ({
          key: row.VendorDeviceId, // I added this line
          vendordeviceid:row.VendorDeviceId,
          ProductSKU: row.ProductSKU,
          isMorningDeficit: row.isMorningDeficit,
          PortalProductName: row.PortalProductName,
          stock: (parseInt(res.morningsoftcountstatus) === 0 ? row.MorningStock : row.NightStock),
        }))
        const vendordevice = _.orderBy(results, ['stock'],['desc']);
        this.setState({loading: false, vendordevice: vendordevice,softvalue:sessionStorage.getItem('softvalue') })
      })
      .catch(err =>
      {
        console.log(err)
        message.error(err.message);
        this.props.history.push("/dashboard");
      })
    }
    else
    {
   const vendordevice = _.orderBy(devicedata, ['stock'],['desc']);
   this.setState({loading: false, vendordevice: vendordevice,softvalue:sessionStorage.getItem('softvalue') });
    }

  }
}
  
handleback = (e) => {
    this.props.history.push("/dashboard");
} 
handleconfirm = (e) => {
    this.props.history.push("/success");
} 
onFinish = values => {
  console.log('Success:', values);
  this.setState({loading: true})
  this.props.softcountaddconfirm(values, this.state.softvalue, this.state.vendordevice).then(res => {
  sessionStorage.removeItem('softvalue');
  sessionStorage.removeItem('state');
  this.setState({loading: false})
  this.props.history.push("/success");
  })
  .catch(err=>{
    message.error(err.message);
    this.props.history.push("/dashboard");
  })
};

onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

  render() {
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      };
    const columns = [
        {
          title: 'Product SKU',
          dataIndex: 'ProductSKU',
          key: 'ProductSKU',
          render: (text, record) => (
            
              <span>
              {record.isMorningDeficit === 1 ? <span className="text-danger"> {record.ProductSKU}</span> : <span> {record.ProductSKU}</span>}
             
           </span>
          ),
        },
        {
          title: 'Product Name',
          dataIndex: 'PortalProductName',
          key: 'PortalProductName',
          render: (text, record) => (
            <span>
               {record.isMorningDeficit === 1 ? <span className="text-danger">{record.PortalProductName}</span> : <span>{record.PortalProductName}</span>}
              
            </span>
          ),
        },
        {
          title: 'Stock',
          dataIndex: 'stock',
          key: 'stock',
          render: (text, record) => (
           
            <span>
            {record.isMorningDeficit === 1 ? <span className="text-danger"> {record.stock}</span> : <span> {record.stock}</span>}
           
         </span>
          ),
        },
        
      ];
    return (
      <div className="dashboard-wrapper">
         <Spin indicator={antIcon} spinning={this.state.loading}>
    <Title level={4}>Inventory Soft Count - {this.state.softvalue === "1" ? "Morning" : "Night"}</Title>
        <div style={{marginTop:35}}>
        <Table columns={columns} dataSource={this.state.vendordevice} bordered pagination={false} size="middle" />
        </div>
        <div style={{marginTop:35}}>
        <Form
      name="basic"
      layout="vertical"
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
    >
        <Form.Item 
        wrapperCol={{ ...layout.wrapperCol}} 
        name="comments" 
        label="Comments"
        rules={[{ required: true, message: 'Please input your comments!' }]}
        >
        <Input.TextArea rows={4} />
      </Form.Item>
      <div className="pull-right" style={{marginTop:15}}>
          <Button htmlType="submit" type="primary" style={{marginRight:15}}>Confirm</Button>
          <Button onClick={this.handleback}>Cancel</Button>
          </div>
      </Form>
      </div>
      </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, {softcountaddconfirm,softcountaddreview})(Confirmation);
