import React, { Component } from "react";
import { Layout, Menu } from "antd";
import Logo from "../assets/img/wds-logo.svg";
import {Link} from "react-router-dom";
import {CommentOutlined,SyncOutlined} from '@ant-design/icons';
import windowSize from 'react-window-size';
import { createBrowserHistory } from 'history'
const { Sider } = Layout;

class Navi extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      collapsed:true
    };
  }
  componentDidMount() {
    const history = createBrowserHistory();
    // Get the current location.
    const location = history.location;
    console.log(location.pathname);
    this.setState({current: location.pathname});
  }
  

  // toggle = () => {
  //   this.setState({
  //     collapsed: !this.state.collapsed
  //   });
  // };
  // onCollapse = collapsed => {
  //   // console.log(collapsed);
  //   this.setState({ collapsed });
  // };
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({collapsed});
  };
  handleClick = e => {
    // console.log('click ', e);
    this.setState({
      current: e.key
    });
  };
  handleresClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
      collapsed: true
    });
  };
  render() {
    return (
      <Sider
        collapsed={this.state.collapsed}
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={this.onCollapse}
        onBreakpoint={broken => {
          console.log(broken);
        }}
        style={{
          overflow: "visible",
          height: "100vh",
          position: "fixed",
          left: 0
        }}
      >
        <div className="logomenu">
          <div className="logo text-center">
            {/* <h5>SimpliNEET</h5> */}
            <img src={Logo} className="logoimg img-responsive" alt="logo" />
          </div>
        </div>
        
        <Menu
          theme="dark"
          mode="inline"
          onClick={
            this.props.windowWidth >= 768
              ? this.handleClick
              : this.handleresClick
          }
          selectedKeys={[this.state.current]}
          style={{ lineHeight: "64px" }}
        >
           <Menu.Item key="/dashboard" title={null}>
            <Link to="/dashboard">
            <SyncOutlined />
              <span className="nav-text">
              Soft count</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/support" title={null}>
            <Link to="/support">
              <CommentOutlined />
              <span className="nav-text">
              Support</span>
            </Link>
          </Menu.Item>
        
         
        </Menu>      
      </Sider>
    );
  }
}



export default windowSize(Navi);
