import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { Avatar, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

class Profilemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      photoURL: ""
    };
  }
  
  render() {
    const menu = (
      <Menu>
        <Menu.Item>
        <Link to="#" onClick={() => this.props.logout()}>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={['click']} >
      <a href="#" className="ant-dropdown-link">
      <Avatar style={{ color: '#fff', marginRight:5, backgroundColor: '#BDEE7C' }}> {this.props.user.empname.charAt(0)}</Avatar>  {this.props.user.empname}
      </a>
    </Dropdown>
    
  
  
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, { logout })(Profilemenu);
