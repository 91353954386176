import React from "react";
import "../assets/css/loading.css";

class Loading extends React.Component {
  render() {
    return (
      <div className="flex-center position-ref full-height">
        <div className="wrap">
  <div className="loading">
    <div className="bounceball"></div>
    <div className="text">NOW LOADING</div>
  </div>
</div>
      </div>
    );
  }
}
export default Loading;
