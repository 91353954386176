import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/App.css";
import { Card, Spin, Input, Form, Button, Select,message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getsubject,addsupport } from "../actions/wdsAction";
import _ from "lodash";

const { Option } = Select;


const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

class Dashboard extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      subject: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.props
      .getsubject()
      .then((res) => {
        this.setState({ loading: false, subject: this.props.subject });
      })
      .catch((err) => {});
  }

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  rendersubject() {
    return _.map(this.state.subject, (subject, key) => {
      return (
        <Option key={key} value={subject.SubjectId}>
          {subject.Subject}
        </Option>
      );
    });
  }
  onReset = () => {
    this.formRef.current.resetFields()
  };
  onFinish = (values) => {
    console.log(values);
    this.setState({ loading: true });
    this.props.addsupport(values)
    .then(res=>{
        message.success(res.message);
        this.setState({ loading: false });
        this.formRef.current.resetFields()
    })
    .catch(err=>{
        message.success(err);
        this.setState({ loading: false });
    })
  };
  //==================================================================================================
  render() {
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 4,
        span: 16,
      },
    };
    return (
      <div className="dashboard-wrapper">
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <Card title="Support" bordered={false}>
            <Form
            ref={this.formRef}
              name="support"
              {...layout}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                wrapperCol={{ ...layout.wrapperCol }}
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{ ...layout.wrapperCol }}
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input valid email id",
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{ ...layout.wrapperCol }}
                name="subject"
                label="Subject"
                rules={[
                  { required: true, message: "Please input your comments!" },
                ]}
              >
                <Select onChange={this.handleChange}>
                  {this.rendersubject()}
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{ ...layout.wrapperCol }}
                name="message"
                label="Message"
                rules={[
                  { required: true, message: "Please input your comments!" },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginRight: 15 }}
                >
                  Submit
                </Button>
                <Button onClick={this.onReset}>Cancel</Button>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, subject: state.subject };
}

export default connect(mapStateToProps, { getsubject,addsupport })(Dashboard);
