import {
  GET_INFO,
  APIURL,
  GET_DEVICE,
  GET_STATUS,
  GET_SUBJECT,
} from "../actionTypes";
import request from "superagent";

// GET_ALL_RESULTS
export function getDistrictandStoreinfo() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request
        .get(APIURL + "/getDistrictandStoreinfo")
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          dispatch({
            type: GET_INFO,
            payload: res.body,
          });
          resolve(res.body);
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}
// GET_ALL_RESULTS
export function getsoftcountanddeviceinfo(brandid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var userid = localStorage.getItem("user");
      var AuthToken = JSON.parse(userid);
      var obj_arr = {
        AuthToken: AuthToken.AuthToken,
        brandid: brandid,
      };
      console.log(obj_arr);
      request
        .post(APIURL + "/getsoftcountanddeviceinfo")
        .send(obj_arr)
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          if (res.body.result_code === 200) {
            dispatch({
              type: GET_DEVICE,
              payload: res.body,
            });
            resolve(res.body);
          } else {
            reject(res.body);
          }
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}

export function softcountaddreview(softtype, vendordeviceinfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var userid = localStorage.getItem("user");
      var user = JSON.parse(userid);

      var obj_arr = {
        AuthToken: user.AuthToken,
        storeid: user.storeid,
        employeeid: user.employeeid,
        softcounttypeid: softtype,
        vendordeviceinfo: JSON.stringify(vendordeviceinfo),
        type: "review",
      };
      request
        .post(APIURL + "/softcountadd")
        .send(obj_arr)
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          if (res.body.result_code === 200) {
            dispatch({
              type: GET_STATUS,
              payload: res.body,
            });
            resolve(res.body);
          } else {
            reject(res.body);
          }
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}

export function softcountaddconfirm(val, softtype, vendordeviceinfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var userid = localStorage.getItem("user");
      var user = JSON.parse(userid);

      var obj_arr = {
        AuthToken: user.AuthToken,
        storeid: user.storeid,
        employeeid: user.employeeid,
        softcounttypeid: softtype,
        comments: val.comments,
        vendordeviceinfo: JSON.stringify(vendordeviceinfo),
        type: "confirm",
      };
      request
        .post(APIURL + "/softcountadd")
        .send(obj_arr)
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          if (res.body.result_code === 200) {
            dispatch({
              type: GET_STATUS,
              payload: res.body,
            });
            resolve(res.body);
            sessionStorage.removeItem("softvalue");
            sessionStorage.removeItem("state");
            sessionStorage.removeItem("vendordevice");
          } else {
            reject(res.body);
          }
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}

export function getsubject() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request
        .get(APIURL + "/getsubject")
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          resolve(res.body);
          dispatch({
            type: GET_SUBJECT,
            payload: res.body.data,
          });
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}

export function addsupport(res) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        name: res.name,
        email: res.email,
        subject: res.subject,
        message: res.message,
      };
      request
        .post(APIURL + "/addsupport")
        .send(obj_arr)
        .then((res) => {
          // res.body, res.headers, res.status
          console.log(res.body, res.headers, res.status);
          if (res.body.result_code === 200) {
            resolve(res.body);
          } else {
            reject(res.body.message);
          }
        })
        .catch((err) => {
          // err.message, err.response
          reject(err.message);
        });
    });
  };
}
