import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import appReducers from "./appReducers";
import deviceReducers from "./deviceReducer";
import statusReducer from "./statusReducer";
import subjectReducers from "./subjectReducers";

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  info: appReducers,
  device: deviceReducers,
  statusinfo:statusReducer,
  subject:subjectReducers
});

export default rootReducer;
