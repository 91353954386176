import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/App.css";
import {
  Button,
  Tabs,
  List,
  Typography,
  Radio,
  Input,
  Spin,
  message,
  Tag,
  Popconfirm,
} from "antd";
import { getsoftcountanddeviceinfo } from "../actions/wdsAction";
import { logout } from "../actions/userAction";
import {
  MinusOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import Clock from "react-live-clock";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const { TabPane } = Tabs;

class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    // state
    this.state = {
      value: 1,
      softvalue: 1,
      softcountype: [],
      productbrand: [],
      vendordevice: [],
      devicelist: [],
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    sessionStorage.setItem("softvalue", this.state.softvalue);
    this.props
      .getsoftcountanddeviceinfo(this.state.value)
      .then((res) => {
        // res.body, res.headers, res.status
        console.log(res);
        const serializedState = sessionStorage.getItem("state");
        if (serializedState === null) {
          this.setState({
            softcountype: res.softcountype,
            productbrand: res.productbrand,
            vendordevice: res.vendordevice,
            devicelist: res.vendordevice,
            loading: false,
            softtypeactive: res.device,
            device: res,
          });
        } else {
          this.setState({
            softcountype: res.softcountype,
            productbrand: res.productbrand,
            vendordevice: JSON.parse(serializedState),
            loading: false,
            softtypeactive: res.device,
            device: res,
          });
        }

        this.ondevicelist(this.state.value);
      })
      .catch((err) => {
        message.error(err.message);
        this.props.logout();
      });
  }

  ondevicelist = (e) => {
    console.log(e);
    this.setState({ value: e });
    var prodeal = this.state.vendordevice;
    console.log(prodeal);
    var uniquename = _.filter(prodeal, function (o) {
      return o.ProductBrandId === e.toString();
    });
    if (uniquename.length === 0) {
      this.setState({ loading: true });
      this.props.getsoftcountanddeviceinfo(e).then((res) => {
        const array1 = this.state.vendordevice;
        const array2 = res.vendordevice;
        const array3 = array1.concat(array2);
        this.setState({
          vendordevice: array3,
          devicelist: res.vendordevice,
          loading: false,
        });
        console.log(array3);
      });
    } else {
      console.log(uniquename);
      this.setState({ devicelist: uniquename });
    }
  };

  onChange = (e) => {
    var vendordevice = this.state.vendordevice;
    var results = vendordevice.map((row) => ({
      key: row.VendorDeviceId, // I added this line
      vendordeviceid: row.VendorDeviceId,
      ProductSKU: row.ProductSKU,
      PortalProductName: row.PortalProductName,
      stock: this.state.softvalue === 1 ? row.MorningStock : row.NightStock,
    }));
    const serializedState1 = JSON.stringify(results);
    sessionStorage.setItem("vendordevice", serializedState1);
    console.log(results);
    this.props.history.push({
      pathname: "/confirm",
      data: results, // your data array of objects
    });
  };
  onChangee = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      softvalue: e.target.value,
    });
    sessionStorage.setItem("softvalue", e.target.value);
  };
  callback = () => {};
  myFunction = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementsByClassName("ant-list-items");
    console.log(ul);
    li = document.getElementsByClassName("ant-list-item");
    //console.log(li);
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("h5")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  rendersoftcountype() {
    return _.map(this.state.softcountype, (softcountype, key) => {
      return (
        <Radio key={key} value={softcountype.SoftCountTypeId}>
          {softcountype.SoftCountTypeName}
        </Radio>
      );
    });
  }
  setcount = (e) => {
    if (this.state.softvalue === 1) {
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        MorningStock: Number(newArray[elementsIndex].MorningStock) + 1,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        MorningStock: Number(newArray1[elementsIndex1].MorningStock) + 1,
        isMorningUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    } else {
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        NightStock: Number(newArray[elementsIndex].NightStock) + 1,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        NightStock: Number(newArray1[elementsIndex1].NightStock) + 1,
        isNightUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    }
  };

  decreasecount = (e) => {
    if (this.state.softvalue === 1) {
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        MorningStock: Number(newArray[elementsIndex].MorningStock) - 1,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        MorningStock: Number(newArray1[elementsIndex1].MorningStock) - 1,
        isMorningUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    } else {
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        NightStock: Number(newArray[elementsIndex].NightStock) - 1,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        NightStock: Number(newArray1[elementsIndex1].NightStock) - 1,
        isNightUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    }
  };
  handleChange(e) {
    if (this.state.softvalue === 1) {
      console.log(e.target.value, this.state.devicelist);
      console.log(e.target.id);
      let value = parseInt(e.target.value);
      let id = parseInt(e.target.id);
      let deviceList = this.state.devicelist;
      deviceList.find(
        (item) => item.VendorDeviceId === id
      ).MorningStock = value;
      // // const elementsIndex = deviceList.filter(
      // //   (element) => element.VendorDeviceId === id
      // // );
      this.setState({
        devicelist: deviceList,
      });
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e.target.id
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        MorningStock: e.target.value,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e.target.id
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        MorningStock: e.target.value,
        isMorningUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    } else {
      console.log(e.target.value, this.state.devicelist);
      console.log(e.target.id);
      let value = parseInt(e.target.value);
      let id = parseInt(e.target.id);
      let deviceList = this.state.devicelist;
      deviceList.find((item) => item.VendorDeviceId === id).NightStock = value;
      // // const elementsIndex = deviceList.filter(
      // //   (element) => element.VendorDeviceId === id
      // // );
      this.setState({
        devicelist: deviceList,
      });
      const elementsIndex = this.state.devicelist.findIndex(
        (element) => element.VendorDeviceId === e.target.id
      );
      let newArray = [...this.state.devicelist];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        NightStock: e.target.value,
      };
      //=========== Vendor Device List ====================
      const elementsIndex1 = this.state.vendordevice.findIndex(
        (element) => element.VendorDeviceId === e.target.id
      );
      let newArray1 = [...this.state.vendordevice];
      newArray1[elementsIndex1] = {
        ...newArray1[elementsIndex1],
        NightStock: e.target.value,
        isNightUpdated: 1,
      };

      this.setState({
        devicelist: newArray,
        vendordevice: newArray1,
      });
      this.saveToLocalStorage(newArray1);
    }
  }
  saveToLocalStorage = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      sessionStorage.setItem("state", serializedState);
    } catch (e) {
      console.log(e);
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  handlereset = (e) => {
    this.setState({ loading: true });
    sessionStorage.removeItem("state");
    this.props
      .getsoftcountanddeviceinfo()
      .then((res) => {
        // res.body, res.headers, res.status
        console.log(res.body);
        const serializedState = sessionStorage.getItem("state");
        if (serializedState === null) {
          this.setState({
            softcountype: res.softcountype,
            productbrand: res.productbrand,
            vendordevice: res.vendordevice,
            loading: false,
          });
        } else {
          this.setState({
            softcountype: res.softcountype,
            productbrand: res.productbrand,
            vendordevice: JSON.parse(serializedState),
            loading: false,
          });
        }

        this.ondevicelist(this.state.value);
      })
      .catch((err) => {
        // err.message, err.response
      });
  };

  //==================================================================================================
  render() {
    const { device, user } = this.props;
    const { softvalue } = this.state;
    return (
      <div className="dashboard-wrapper">
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <div className="dash-header">
            <span className="dash-time">
              {this._isMounted === true ? (
                <span>
                  <ClockCircleOutlined />{" "}
                  <Clock
                    format={"MM-DD-YYYY LTS"}
                    ticking={false}
                    timezone={"America/Chicago"}
                  />
                </span>
              ) : null}
            </span>
            <span>
              <Tag style={{ marginBottom: 15 }} color="geekblue">
                <b>{user.storename}</b>
              </Tag>
            </span>
            <Radio.Group
              className="pull-right"
              size="large"
              onChange={this.onChangee}
              value={this.state.softvalue}
            >
              {this.rendersoftcountype()}
            </Radio.Group>
          </div>
          <div className="search-header">
            <Input
              placeholder="Search device by name"
              id="myInput"
              onKeyUp={() => this.myFunction()}
              prefix={<SearchOutlined />}
            />
          </div>
          <Tabs
            onChange={this.ondevicelist}
            defaultActiveKey="1"
            animation={true}
            type="card"
          >
            {this.state.productbrand.map((d) => (
              <TabPane tab={d.ProductBrand} key={d.ProductBrandId}>
                {this.state.softvalue === 1 ? (
                  <List
                    bordered
                    dataSource={this.state.devicelist}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() =>
                              this.decreasecount(item.VendorDeviceId)
                            }
                            disabled={
                              item.MorningStock === 0 ||
                              parseInt(device.morningsoftcountstatus) === 1
                                ? true
                                : false
                            }
                            shape="circle"
                            icon={<MinusOutlined />}
                          />,
                          // <span>{item.MorningStock}</span>
                          <Input
                            id={item.VendorDeviceId.toString()}
                            // defaultValue={item.MorningStock}
                            // value = {item.MorningStock}
                            value={
                              this.state.devicelist.find(
                                (item1) =>
                                  item1.VendorDeviceId === item.VendorDeviceId
                              ).MorningStock
                            }
                            type="number"
                            onChange={this.handleChange}
                          />,
                          <Button
                            disabled={
                              parseInt(device.morningsoftcountstatus) === 1
                                ? true
                                : false
                            }
                            onClick={() => this.setcount(item.VendorDeviceId)}
                            shape="circle"
                            icon={<PlusOutlined />}
                          />,
                        ]}
                      >
                        <Typography.Text>
                          <h5
                            className={
                              item.isMorningDeficit === 1 ? "text-danger" : ""
                            }
                          >
                            {item.PortalProductName}
                          </h5>
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                ) : (
                  <List
                    bordered
                    dataSource={this.state.devicelist}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() =>
                              this.decreasecount(item.VendorDeviceId)
                            }
                            disabled={
                              item.NightStock === 0 ||
                              parseInt(device.nightsoftcountstatus) === 1
                                ? true
                                : false
                            }
                            shape="circle"
                            icon={<MinusOutlined />}
                          />,
                          // <span>{item.NightStock}</span>,
                          <Input
                            id={item.VendorDeviceId.toString()}
                            // defaultValue={item.MorningStock}
                            // value = {item.MorningStock}
                            value={
                              this.state.devicelist.find(
                                (item1) =>
                                  item1.VendorDeviceId === item.VendorDeviceId
                              ).NightStock
                            }
                            type="number"
                            onChange={this.handleChange}
                          />,
                          <Button
                            disabled={
                              parseInt(device.nightsoftcountstatus) === 1
                                ? true
                                : false
                            }
                            onClick={() => this.setcount(item.VendorDeviceId)}
                            shape="circle"
                            icon={<PlusOutlined />}
                          />,
                        ]}
                      >
                        <Typography.Text>
                          <h5
                            className={
                              item.isNightDeficit === 1 ? "text-danger" : ""
                            }
                          >
                            {item.PortalProductName}
                          </h5>
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
          {softvalue === 1 ? (
            <span>
              {parseInt(device.morningsoftcountstatus) === 0 && (
                <div className="pull-right" style={{ marginTop: 15 }}>
                  <Button
                    onClick={this.onChange}
                    type="primary"
                    style={{ marginRight: 15 }}
                  >
                    Submit
                  </Button>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    onConfirm={this.handlereset}
                    cancelText="No"
                  >
                    <Button>Clear</Button>
                  </Popconfirm>
                </div>
              )}{" "}
            </span>
          ) : null}

          {softvalue === 2 ? (
            <span>
              {parseInt(device.nightsoftcountstatus) === 0 && (
                <div className="pull-right" style={{ marginTop: 15 }}>
                  <Button
                    onClick={this.onChange}
                    type="primary"
                    style={{ marginRight: 15 }}
                  >
                    Submit
                  </Button>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    onConfirm={this.handlereset}
                    cancelText="No"
                  >
                    <Button>Clear</Button>
                  </Popconfirm>
                </div>
              )}{" "}
            </span>
          ) : null}
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, info: state.info, device: state.device };
}

export default connect(mapStateToProps, {
  getsoftcountanddeviceinfo,
  logout,
})(Dashboard);
