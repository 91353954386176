import React from "react";
import ReactDOM from "react-dom";

import LoadingComponent from "./component/LoadingComponent";
import AuthenticatedCompoment from "./component/AuthenticatedComponent";
import LogIn from "./component/login";
import Support from "./component/Support";
import Dashboard from "./component/Dashboard";
import Confirmation from "./component/Confirmation";
import Success from "./component/Success";
import Navi from './routes/Header';
import Profilemenu from "./component/headerprofile";

import * as serviceWorker from "./serviceWorker";
import { Layout } from "antd";
import "./assets/css/App.css";
import "antd/dist/antd.css";
//Redux
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import history from "./history";
const { Header, Content, Footer } = Layout;

// create redux store -> reducers -> 'actions - actionType' | applyMiddleware()
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <LoadingComponent>
        <div>
          <Switch>
            <Route path="/" component={LogIn} exact={true} />
            <AuthenticatedCompoment>
            <Layout>
            <Navi />
              <Layout className="mainlayout">
              <Header style={{ padding: "0 15px", textAlign: 'right' }} >
                        <Profilemenu />
                    </Header>
                <Content>
                  <Route path="/dashboard" component={Dashboard} exact={true} />
                  <Route path="/confirm" component={Confirmation} exact={true} />
                  <Route path="/success" component={Success} exact={true} />
                  <Route path="/support" component={Support} exact={true} />
                </Content>
                <Footer style={{ textAlign: "center" }}>
              © 2019 - 2021 WDS Web Application. v.1.0.3
            </Footer>
              </Layout>
            </Layout>
            </AuthenticatedCompoment>
          </Switch>
          
        </div>
        </LoadingComponent>
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
